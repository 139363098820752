import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { toggleMenu, smoothScroll, observeElements } from './index';

document.addEventListener('DOMContentLoaded', () => {
  if (document.body.classList.contains('body--balmax')) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    setTimeout(() => {
      disablePageScroll(document.documentElement);
      toggleMenu();
      smoothScroll();
      animFrontBalmax();
      observeElements();
      submitFormBalmax();
    }, 150);
  }
});

const submitFormBalmax = function () {
  const form = document.querySelector('.contacts__form');

  form.addEventListener('submit', function (evt) {
    evt.preventDefault();

    const name = form.querySelector('.form__name').value;
    const email = form.querySelector('.form__mail').value;
    const comment = form.querySelector('.form__textarea').value;

    const body = `${comment}\n\nС уважением,\n${name}\n${email}`;
    const mailtoLink = `mailto:balmax@evraz.com?subject=Заявка%20с%20формы%20обратной%20связи%20на%20сайте%20Балмакс&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  });
};

const animFrontBalmax = function () {
  const header = document.querySelector('.header');
  const front = document.querySelector('.front');

  const frontPic = front.querySelector('.front__pic');
  const frontSubtitle = front.querySelector('.front__subtitle');
  const frontTitle = front.querySelector('.front__title');
  const frontNote = front.querySelector('.front__note');

  addClass(frontPic, 50)
    .then(() => addClass(frontTitle, 400))
    .then(() => addClass(frontSubtitle, 400))
    .then(() => addClass(frontNote, 400))
    .then(() => addClass(header, 400))
    .then(() => enablePageScroll(document.documentElement));

  function addClass(element, delay) {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log(element);
        element.classList.add('anim');
        resolve();
      }, delay);
    });
  }
};
