window.EVRAZ = {};

import './accs';
import { enablePageScroll } from 'scroll-lock';
import { throttle } from 'throttle-debounce';

export function observeElements() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.target.classList.contains('anim') && entry.isIntersecting) {
          entry.target.classList.add('anim');
        }
      });
    },
    {
      threshold: 0.15,
    },
  );

  const animBase = document.querySelectorAll('.animBlock');
  const animLeft = document.querySelectorAll('.animLeft');
  const animRight = document.querySelectorAll('.animRight');
  const animBottom = [...document.querySelectorAll('.animBottom')].filter((el) => !el.closest('.front'));

  const animBlocks = [...animBase, ...animLeft, ...animRight, ...animBottom];

  animBlocks.forEach((animBlock) => {
    observer.observe(animBlock);
  });

  window.addEventListener(
    'scroll',
    throttle(300, () => {
      animBlocks.forEach((animBlock) => {
        observer.observe(animBlock);
      });
    }),
    false,
  );
}

export function animFront() {
  const header = document.querySelector('.header');
  const front = document.querySelector('.front');

  const frontDecor = front.querySelector('.decor');
  const frontSubtitle = front.querySelector('.front__subtitle');
  const frontTitle = front.querySelector('.front__title');
  addClass(frontDecor, 50)
    .then(() => addClass(frontTitle, 1100))
    .then(() => addClass(frontSubtitle, 400))
    .then(() => addClass(header, 400))
    .then(() => enablePageScroll(document.documentElement));

  function addClass(element, delay) {
    return new Promise((resolve) => {
      setTimeout(() => {
        element.classList.add('anim');
        resolve();
      }, delay);
    });
  }
}

export function toggleMenu() {
  const body = document.body;
  const menuToggle = body.querySelector('.header__burger');

  menuToggle.addEventListener('click', () => body.classList.toggle('menu-open'));
}

export function smoothScroll() {
  document.querySelectorAll('.header__link').forEach((link) => {
    link.addEventListener('click', function (e) {
      e.preventDefault();

      const targetId = this.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);

      targetElement.scrollIntoView({
        behavior: 'smooth',
      });
    });
  });
}

export function submitForm() {
  const form = document.querySelector('.contacts__form');

  form.addEventListener('submit', function (evt) {
    evt.preventDefault();

    const name = form.querySelector('.form__name').value;
    const email = form.querySelector('.form__mail').value;
    const comment = form.querySelector('.form__textarea').value;

    const body = `${comment}\n\nС уважением,\n${name}\n${email}`;
    const mailtoLink = `mailto:armax@evraz.com?subject=Заявка%20с%20формы%20обратной%20связи%20на%20сайте%20Армакс&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  });
}
