import { throttle } from 'throttle-debounce';

const config = {
  closeSiblings: true,
  tabsViewport: 889,
};

class CAccordions_base {
  constructor() {
    this.config = Object.assign({}, config);

    this.id = null;
    this.acc = null;

    this.sections = null;
    this.firstSection = null;
    this.tabs = null;
    this.buttons = null;
    this.buttonClose = null;

    this.accHasTabs = false;
    this.tabsView = true;

    this.inProgress = false;
  }

  /**
   * Инициализация
   */
  init(accordion, index) {
    this.id = `acc-№${index}`;
    this.acc = accordion;

    this.sections = [...accordion.querySelectorAll('.js-acc-section')];
    this.firstSection = this.sections[0];

    this.tabs = [...accordion.querySelectorAll('.js-acc-tab')];
    this.buttons = [...accordion.querySelectorAll('.js-acc-button')];
    this.buttonClose = accordion.querySelector('.js-acc-button-close');

    this.createConfig();

    // Переменная, отвечающая за изменение экрана, когда аккордеоны прерващаются в табы
    this.tabsView = window.innerWidth > this.config.tabsViewport;

    if (this.tabs.length) {
      this.accHasTabs = true;
      this.handleTabs();
    }

    this.openExpandedSections();

    accordion.addEventListener(
      'click',
      throttle(
        300,
        function (evt) {
          if (this.inProgress) {
            return;
          }

          const targetButton = evt.target.closest('.js-acc-button');
          const targetTabButton = evt.target.closest('.js-acc-tab-button');
          const targetButtonClose = evt.target.closest('.js-acc-button-close');

          if (targetButton) {
            const section = targetButton.closest('.js-acc-section');
            this.toggle(section);
          }

          if (targetTabButton) {
            const tab = targetTabButton.closest('.js-acc-tab');
            this.toggleTabs(tab);
          }

          if (targetButtonClose) {
            this.closeAll(this);
          }
        }.bind(this),
      ),
    );
  }

  // Объединение глобального и локального конфига
  createConfig() {
    for (const key in this.acc.dataset) {
      const value = this.acc.dataset[key];

      this.config[key] = !isNaN(value) ? parseInt(value) : value;

      if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
        this.config[key] = JSON.parse(value);
      }
    }
  }

  // Смена табов / аккордеонов
  handleTabs() {
    const openedSection = this.sections.filter((section) => section.classList.contains('is-expand'));

    if (!this.tabsView) {
      this.acc.classList.add('acc--no-tabs');
    }

    // Если есть табы, нет хеша среди секций и открытых табов, открыть первый таб
    if (this.tabsView) {
      this.open(this.firstSection, true);
    }

    // Следим за изменением ширины экрана
    window.addEventListener(
      'resize',
      throttle(300, () => {
        const newTabsView = window.innerWidth > this.config.tabsViewport;

        newTabsView ? this.acc.classList.remove('acc--no-tabs') : this.acc.classList.add('acc--no-tabs');

        // Если аккордеоны превращаются в табы
        if (newTabsView && !this.tabsView) {
          this.tabsView = newTabsView;
          const openedSections = this.sections.filter((section) => section.classList.contains('is-expand'));

          // Если нет открытых табов, открыть первый
          if (openedSections.length === 0) {
            this.open(this.firstSection);
          }

          // Если есть больше 1 открытых табов, открыть только первый, остальные закрыть
          if (openedSections.length > 1) {
            this.closeSiblings(this.firstSection);
            this.open(this.firstSection);
          }

          // Если был открыт только один акк, то он и останется открытым
        }

        this.tabsView = newTabsView;
      }),
    );
  }

  // Открыть секции с 'is-expand'
  openExpandedSections() {
    const openedSections = this.sections.filter((section) => section.classList.contains('is-expand'));

    // Открыть только те секции в состоянии акков. В состоянии табов они регулрируются в handleTabs
    this.accHasTabs && this.tabsView ? '' : openedSections.forEach((openedSection) => this.open(openedSection));
  }

  /**
   * Открыть / закрыть секцию аккордеона
   * @param {object} event событие клика
   */
  toggle(section, closeSiblings = this.config.closeSiblings) {
    if (section.classList.contains('is-expand')) {
      this.close(section);
    } else {
      if (closeSiblings) {
        this.closeSiblings();
      }

      this.open(section);
    }
  }

  // Открыть / закрыть таб
  toggleTabs(tab) {
    const section = this.sections.find((section) => section.dataset.href === tab.dataset.href);

    if (!section.classList.contains('is-expand')) {
      this.toggle(section, true);
    }
  }

  /**
   * Открыть секцию аккордеона
   * @param {object} button кнопка секции
   * @param {number} duration длительность анимации открытия
   * @param {number} delay длительность задержки анимации открытия
   */
  async open(section, delay = 0) {
    const article = section.querySelector('article');
    let targetHeight = null;

    if (!this.accHasTabs || !this.tabsView) {
      article.style.display = 'block';

      const initialHeight = article.offsetHeight; // Текущая высота
      article.style.height = 'auto'; // Устанавливаем высоту на 'auto', чтобы получить полную высоту контента
      targetHeight = article.offsetHeight; // Сохраняем полную высоту контента
      article.style.height = `${initialHeight}px`; // Возвращаем обратно текущую высоту
    }

    await new Promise((resolve) => setTimeout(resolve, delay));

    if (!this.accHasTabs || !this.tabsView) {
      section.classList.add('is-expand');
      article.style.height = `${targetHeight}px`;

      article.addEventListener('transitionend', function handler() {
        setTimeout(() => (article.style.height = 'auto'), 100);
        article.removeEventListener('transitionend', handler);
      });
    } else {
      article.style.display = 'block';
      article.style.height = 'auto';
      section.classList.add('is-expand');
    }

    if (this.accHasTabs) {
      const tab = this.tabs.find((tab) => tab.dataset.href === section.dataset.href);
      tab.classList.add('is-expand');
    }
  }

  /**
   * Закрыть секцию аккордеона
   * @param {object} button кнопка секции
   * @param {number} delay длительность задержки анимации открытия
   */
  async close(section, delay, externalCall = false) {
    if (this.accHasTabs && externalCall && this.tabsView) {
      const openedTab = this.tabs.find((tab) => tab.classList.contains('is-expand'));

      if (openedTab && openedTab.dataset.href === section.dataset.href) {
        return;
      }
    }

    const article = section.querySelector('article');
    const initialHeight = article.offsetHeight;

    await new Promise((resolve) => setTimeout(resolve, delay));

    if (!this.accHasTabs || !this.tabsView) {
      section.classList.remove('is-expand');
      article.style.height = `${initialHeight}px`;

      article.offsetHeight;

      setTimeout(() => (article.style.height = '0px'), 0);

      article.addEventListener('transitionend', function handler() {
        article.style.height = '';
        article.removeEventListener('transitionend', handler);
      });
    } else {
      article.style.display = 'none';
      section.classList.remove('is-expand');
    }

    if (this.accHasTabs) {
      const tab = this.tabs.find((tab) => tab.dataset.href === section.dataset.href);

      tab.classList.remove('is-expand');
    }
  }

  // Закрыть все секции
  closeAll() {
    this.sections.forEach((section) => this.close(section));
  }

  /**
   * Закрыть соседей
   * @param {object} exceptionSection Секция, которую не надо закрывать
   */
  closeSiblings(exceptionSection) {
    const openedSections = this.sections.filter(
      (section) => section.classList.contains('is-expand') && section !== exceptionSection,
    );
    openedSections.forEach((openedSection) => this.close(openedSection));
  }
}

if (document.body.classList.contains('body--balmax')) {
  const cAccordions_base = new CAccordions_base();
  const accordions = document.querySelectorAll('.js-acc');

  accordions.forEach((accordion, i) => {
    cAccordions_base.init(accordion, i);
  });
}
